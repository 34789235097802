import { BaseDto } from '@/shared/dtos/base-dto';

export class admin_registro_llamada extends BaseDto {
    public nombre !: string;
    public apellidos !: string;
    public email !: string;
    public telefono !: string;
    public id_nutricionista !: number;
    public fecha!:Date;
    public pendiente_llamar !: boolean;
    public comentarios !: string;
    public accion !: string;
    public no_llamar !: boolean;
    public me_llama !: boolean;
    public colaborador !: boolean;

 } 

import { store } from '@/store/store';
import { accesos_app } from '@/shared/dtos/admin/accesos_app';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'accesos_appModule',
    store,
    dynamic: true
})
class accesos_appModule extends VuexModule {
    public accesos_apps: accesos_app[] = [];
    public accesos_app: accesos_app = new accesos_app();

    @Action({ commit: 'onGetaccesos_apps' })
    public async getaccesos_apps() {
        return await ssmHttpService.get(API.accesos_app);
    }

    @Action({ commit: 'onGetaccesos_app' })
    public async getaccesos_app(id: any) {
        return await ssmHttpService.get(API.accesos_app + '/' + id);
    }

    @Action
    public async guardaraccesos_app(accesos_app: accesos_app) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.accesos_app, accesos_app.toJson());
    }

    @Action
    public async modificaraccesos_app(accesos_app: accesos_app) {
        await ssmHttpService.put(API.accesos_app, accesos_app);
    }

    @Action
    public async eliminaraccesos_app(accesos_app: accesos_app) {
        await ssmHttpService.delete(API.accesos_app + '/' + accesos_app.id, null, false);
    }

    @Mutation
    public onGetaccesos_apps(res: accesos_app[]) {

        this.accesos_apps = res ? res.map((x) => new accesos_app(x)) : []
    }

    @Mutation
    public onGetaccesos_app(res: accesos_app) {
        this.accesos_app = new accesos_app(res);
    }

}
export default getModule(accesos_appModule);
import { store } from '@/store/store';
import { admin_registro_llamada } from '@/shared/dtos/admin/admin_registro_llamada';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'admin_registro_llamadaModule',
    store,
    dynamic: true
})
class admin_registro_llamadaModule extends VuexModule {
    public admin_registro_llamadas: admin_registro_llamada[] = [];
    public admin_registro_llamada: admin_registro_llamada = new admin_registro_llamada();

    @Action({ commit: 'onGetadmin_registro_llamadas' })
    public async getadmin_registro_llamadas() {
        return await ssmHttpService.get(API.admin_registro_llamada);
    }

    @Action({ commit: 'onGetadmin_registro_llamada' })
    public async getadmin_registro_llamada(id: any) {
        return await ssmHttpService.get(API.admin_registro_llamada + '/' + id);
    }
    
    @Action({ commit: 'onGetadmin_registro_llamadas' })
    public async getGenerarNuevasLLamadas() {
        return await ssmHttpService.get(API.admin_registro_llamada + '/generar_llamadas');
    }
    @Action({ commit: 'onGetadmin_registro_llamadas' })
    public async getAccionNoContestado(id:number) {
        return await ssmHttpService.get(API.admin_registro_llamada + '/accion_no_contestado/'+id);
    }

    @Action
    public async guardaradmin_registro_llamada(admin_registro_llamada: admin_registro_llamada) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.admin_registro_llamada, admin_registro_llamada.toJson());
        this.getadmin_registro_llamadas();
    }

    @Action
    public async modificaradmin_registro_llamada(admin_registro_llamada: admin_registro_llamada) {
        await ssmHttpService.put(API.admin_registro_llamada + '/' + admin_registro_llamada.id, admin_registro_llamada);
        this.getadmin_registro_llamadas();
    }

    @Action
    public async eliminaradmin_registro_llamada(admin_registro_llamada: admin_registro_llamada) {
        await ssmHttpService.delete(API.admin_registro_llamada + '/' + admin_registro_llamada.id, null, false);
        this.getadmin_registro_llamadas();
    }

    @Mutation
    public onGetadmin_registro_llamadas(res: admin_registro_llamada[]) {

        this.admin_registro_llamadas = res ? res.map((x) => new admin_registro_llamada(x)) : []
    }

    @Mutation
    public onGetadmin_registro_llamada(res: admin_registro_llamada) {
        this.admin_registro_llamada = new admin_registro_llamada(res);
    }

}
export default getModule(admin_registro_llamadaModule);
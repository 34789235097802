


































































































































































































































































































































































































import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { RouterNames } from "@/router/routernames";
import { admin_registro_llamada } from "@/shared/dtos/admin/admin_registro_llamada";
import { licencia } from "@/shared/dtos/admin/licencia";
import { Nutricionista } from "@/shared/dtos/admin/Nutricionista";
import { EmailDto } from "@/shared/dtos/Email-dto";
import accesos_appModule from "@/store/modules/Admin/accesos_app-module";
import admin_registro_llamadaModule from "@/store/modules/Admin/admin_registro_llamada-module";
import NutricionistaModule from "@/store/modules/Admin/Nutricionista-module";
import adminnutricionistaModule from "@/store/modules/adminnutricionista-module";
import EmailsModule from "@/store/modules/Emails/Emails-module";
import LicenciaModule from "@/store/modules/Licencia-module";
import { UtilsDate } from "@/utils/utils-date";
import { UtilsNotify } from "@/utils/utils-notify";
import { DxDateBox } from "devextreme-vue";
import { Component, Vue } from "vue-property-decorator";
import { DietaUtilsService } from "../pacientes/hist_dietas/service-dieta-utils";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    DxDateBox,
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class LLamadaForm extends Vue {
  //private llamada: admin_registro_llamada = new admin_registro_llamada();
  private search: string = "";
  private search_ntt: string = "";
  private id_item = 0;
  private dialogo_ntt = false;
  private dialogo_email = false;
  private Email = new EmailDto();

  created() {
    if (admin_registro_llamadaModule.admin_registro_llamadas.length === 0) {
      admin_registro_llamadaModule.getadmin_registro_llamadas();
    }
    if (NutricionistaModule.Nutricionistas.length === 0) {
      NutricionistaModule.getNutricionistas();
    }
    if (accesos_appModule.accesos_apps.length === 0) {
      accesos_appModule.getaccesos_apps();
    }

    this.id_item = Number.parseInt(this.$route.params.id);
  }
  get accesos() {
    return accesos_appModule.accesos_apps.filter(
      (x) => x.id_nutricionista === this.llamada.id_nutricionista
    );
  }

  private get llamada() {
    var ll = admin_registro_llamadaModule.admin_registro_llamadas.find(
      (x) => Number.parseInt(x.id) === this.id_item
    )!;
    if (ll === undefined) {
      return new admin_registro_llamada();
    } else {
      return ll;
    }
  }
  private get licencia_ntt() {
    var lic = NutricionistaModule.Nutricionistas.find(
      (x) => x.id === this.llamada.id_nutricionista
    )!;
    if (lic === undefined) {
      return;
    }
    if (lic.licencia === undefined) {
      lic.licencia = new licencia();
    }
    return lic.licencia;
  }
  private get acciones() {
    var a = admin_registro_llamadaModule.admin_registro_llamadas.map(
      (item) => item.accion
    );
    return a.filter(this.onlyUnique);
  }
  private onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  public get botones() {
    return [
      {
        id: 1,
        titulo: "Ficha",
        descripcion: "Datos del la llamada",
        icono: "fad fa-user-alt ",
        visible_paci_no_existe: true,
        routername: RouterNames.adminllamada,
      },
    ];
  }
  public get datos() {
    return admin_registro_llamadaModule.admin_registro_llamadas.filter(
      (x) =>
        x.id !== this.id_item &&
        x.id_nutricionista === this.llamada.id_nutricionista &&
        x.telefono === this.llamada.telefono
    );
  }

  public get headers() {
    return [
      { text: "Nombre", value: "nombre" },
      { text: "Acción", value: "accion" },
      { text: "Pendiente", value: "pendiente_llamar" },
    ];
  }
  public get datos_ntt() {
    return NutricionistaModule.Nutricionistas.filter((x) => x.id > 0);
  }
  public get headers_ntt() {
    return [
      { text: "Id", value: "id" },
      { text: "Email", value: "email" },
      { text: "Nombre", value: "configuracion.Nombre" },
      { text: "Telefono", value: "configuracion.Telefono1" },
      { text: "Lic.Hasta", value: "licencia.hasta" },
    ];
  }

  get Columns_accesos() {
    var Columnas: ClassColumnDataTable[] = [];

    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha Hora",
        datatypes.datetime,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "accede",
        "Accede",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "ip",
        "Ip",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
  public llamado() {
    this.llamada.pendiente_llamar = false;
    this.llamada.fecha = new Date();
    this.submit();
  }
  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.id_item > 0) {
          admin_registro_llamadaModule.modificaradmin_registro_llamada(
            this.llamada
          );
        } else {
        }
        admin_registro_llamadaModule.guardaradmin_registro_llamada(
          this.llamada
        );
      }
    });
  }
  public Seleccion(llamada: admin_registro_llamada) {
    this.id_item = llamada.id;
    this.$route.params.id = llamada.id;
  }
  public toLlamadas() {
    this.$router.push({ name: RouterNames.adminllamadaslista });
  }
  public eliminar() {
    admin_registro_llamadaModule.eliminaradmin_registro_llamada(this.llamada);
  }
  public showDialog() {
    if (this.id_item >= 0) {
      return;
    }
    this.dialogo_ntt = true;
  }
  public sendMailAcceso() {
    adminnutricionistaModule
      .getsendrecordatorio_pacientes(this.llamada.id_nutricionista)
      .then(() => {
        UtilsNotify.NotificacionSuccess("Email Enviado", "");
        this.llamada.comentarios =
          this.llamada.comentarios + "\n" +  " Envio datos de acceso ";
      });
  }

  public async no_contestado() {
    await admin_registro_llamadaModule.getAccionNoContestado(this.llamada.id);
  }

  public shw_email() {
    this.dialogo_email = true;
  }
  public enviar_email() {
    this.Email.email = this.llamada.email;
    EmailsModule.SendEmailSoporte(this.Email).then(() => {
      UtilsNotify.NotificacionSuccess("Email Soporte Enviado", "");
      this.llamada.comentarios =
        this.llamada.comentarios + "\n" +  " Envio un email de soporte ";
    });
    this.dialogo_email = false;
  }

  public submit_licencia() {
    LicenciaModule.modificarlicencia(this.licencia_ntt!);
  }

  public Seleccion_ntt(e: Nutricionista) {
    this.llamada.nombre = e.configuracion.Nombre;
    this.llamada.apellidos = e.configuracion.Apellidos;
    this.llamada.telefono = e.configuracion.Telefono1.toString();
    this.llamada.email = e.configuracion.Email.toString();
    this.llamada.id_nutricionista = e.configuracion.id.toString();
    this.dialogo_ntt = false;
  }
  public Call() {
    window.open("tel:" + this.llamada.telefono);
  }
  public AddDias(dias: number) {
    var ff = new Date(this.licencia_ntt!.hasta);
    this.licencia_ntt!.hasta = UtilsDate.AddDias(ff, dias);
  }
}
